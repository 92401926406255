<template>
  <el-main>
    <div style="background:#fff;">
      <el-form size="mini" :inline="true" :model="userformdata" ref="userform" :show-message="false">
        <el-tabs type="border-card" v-model="activeName" style="padding-left:0px !important;" >
          <el-tab-pane label="基本设置" name="first">
            <el-form-item label="最大开票金额" label-width="120px" prop="max_price" required>
              <el-input type="number" v-model="userformdata.max_price" autocomplete="off" placeholder="自然年个人最大开票金额"></el-input>
            </el-form-item>
            <el-form-item label="最小开票金额" label-width="120px" prop="min_price" :required="userformdata.uid?false:true">
              <el-input type="number" v-model="userformdata.min_price" placeholder="平台最小开票金额"></el-input>
            </el-form-item>
            <el-form-item label="开票费用比例" label-width="120px" prop="broker" required>
              <el-input v-model="userformdata.broker" placeholder="开票费用比例">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-tab-pane>
          <el-button style="margin-top:20px;" type="primary" @click="dosubmit('userform')" :disabled="isbusy" :loading="isbusy">
            {{ $t('global.submit') }}
          </el-button>
        </el-tabs>
      </el-form>
    </div>
  </el-main>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'setting',
  components: {
  },
  data() {
    return {
      activeName:'first',
      userformdata: {},
      searchinfo: '',
      isbusy: false,
      defaultserdata: {
        id:0,
        max_price:'0.00',
        min_price:'0.00',
        broker:'0.00'
      }
    }
  },
  methods: {
    async getlist() {
      this.isbusy = true;
      let res = await this.httpget('/system/setting/index');
      if (res.code === 0) {
        this.userformdata = res.data;
        this.isbusy = false;
      } else {
        this.isbusy = false;
      }

    },
    dosubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.suresubmit();
        } else {
          return false;
        }
      });
    },
    async suresubmit() {
      this.isbusy = true;
      let res = await this.httppost('/system/setting/save', this.userformdata);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.isshowdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
  },
  mounted() {
    this.getlist();
  },
  computed: {
    baseurl: {
      get: function () {
        return process.env.VUE_APP_API_ROOT;
      }
    },
    langlist: {
      get: function() {
        return this.$store.getters.getLanglist;
      }
    },
  },
}
</script>

<style lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

.preview {
  width: 178px;
  height: 50px;
  display: block;
}

  .tabs {
  padding: 20px 10px;
}
.sertab{
  .el-form{
    display: flex;
    flex-direction: column;
    .el-form-item{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .el-form-item__content{
    width: 100%;
    margin-left: 0px !important;
    margin-right: auto;
  }
  .el-textarea__inner{
    width: 100%;
  }
}
.tip {
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  margin: 5px 0px 10px;
}
.i-addd{
  padding:8px;
  background:#409EFF;
  color:#ffffff;
  border-radius:5px;
  margin-left:10px;
  cursor: pointer;
}
</style>
